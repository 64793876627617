import {NgModule, inject, provideAppInitializer} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {AppComponent} from '@app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatListModule} from "@angular/material/list";
import {ErrorInterceptor, JwtInterceptor, ProfileInterceptor, LocaleInterceptor} from "@app/_helper";
import {AppRoutingModule} from "@app/app-routing.module";
import {AlertComponent} from '@app/component/alert/alert.component';
import {HomeComponent} from '@app/component/home/home.component';
import {MenuComponent} from './component/menu/menu.component';
import {AuthModule} from "@app/module/auth/auth.module";
import {MatButtonModule} from "@angular/material/button";
import {DeleteDialogComponent} from '@app/component/dialog/delete/delete.dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatToolbarModule} from "@angular/material/toolbar";
import {ClientPickerModule} from "@app/module/auth/client/client.picker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {FooterComponent} from './component/footer/footer.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {ConfigService} from "@app/_service/config.service";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatListModule,
    AppRoutingModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    AuthModule,
    ClientPickerModule,
    RecaptchaV3Module,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    DeleteDialogComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ProfileInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true},
    {provide: RECAPTCHA_V3_SITE_KEY, useFactory: (config: ConfigService) => config.googleRecaptchaKeySite, deps: [ConfigService]},
    provideAppInitializer(() => {
      const initializerFn = ((config: ConfigService) => {
        return () => config.loadConfig(); //.then(() => console.debug(`config resolved`, config))
      })(inject(ConfigService));
      return initializerFn();
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
