import {Injectable} from '@angular/core';
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {first, interval, Observable} from "rxjs";
import {Config} from "@app/_model/config";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private static readonly API_URI_PREFIX = `${environment.apiUrl}/config`;
  private static readonly API_URI_LIST = `${ConfigService.API_URI_PREFIX}/list`;

  private static readonly POLL_INTERVAL_SECONDS = 300;

  private config?: Config;

  constructor(
    private http: HttpClient,
  ) {
    this.poll();
  }

  private poll(): void {
    interval(ConfigService.POLL_INTERVAL_SECONDS * 1000)
      .subscribe(() => this.load());
  }

  public async loadConfig(): Promise<void> {
    this.load();
  }

  private load(): void {
    this.list()
      .pipe(first())
      .subscribe(data => {
        this.config = data;
        //console.debug("config:", this.config);
      });
  }

  private list(): Observable<any> {
    const uri = `${ConfigService.API_URI_LIST}`;
    return this.http.get<Config>(uri);
  }

  public get googleRecaptchaKeySite(): string | undefined {
    return this.config?.googleRecaptchaKeySite;
  }

}
