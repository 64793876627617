<div class="text-container">
  <div class="mat-title" i18n>About</div>
  <div class="container">
    <div class="title" i18n>OBiServer Ltd.</div>
    <div><span i18n>E-mail</span>: <span i18n="@@obiserver.email"><a href="mailto:tarhely@obiserver.hu">tarhely&commat;obiserver.hu</a></span></div>
    <div><span i18n>Phone</span>: <span i18n="@@obiserver.phone"><a href="tel:+36703173773">+36-70/317-3773</a></span></div>
    <div><span i18n>Registered office address</span>: <span i18n="@@obiserver.registered_office_address">2660 Balassagyarmat, Zeke Kálmán utca 46/2, Hungary</span></div>
    <div><span i18n>VAT</span>: <span i18n="@@obiserver.vat">HU14743489</span></div>
    <div><span i18n>Director</span>: <span i18n="@@obiserver.director">Balázs Szilfai</span></div>
  </div>
</div>
