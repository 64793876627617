<button
  mat-button
  matTooltip="Active profile"
  *ngIf="enabled"
  [matMenuTriggerFor]="clientPicker"
  [disabled]="!accountClients || accountClients.length == 0">
  <mat-icon>supervisor_account</mat-icon>
  {{accountClient?.client?.name || textNoClient}}
</button>
<mat-menu
  #clientPicker="matMenu"
  xPosition="before">
  <button
    mat-menu-item
    *ngFor="let accountClient of accountClients"
    [textContent]="accountClient.client.name"
    (click)="onClientChanged(accountClient)">
  </button>
</mat-menu>
