<mat-toolbar class="app-toolbar">
  <mat-toolbar-row>
    <div class="left">
      <button
        mat-button
        routerLink="/{{Const.URI_HOME}}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"
        class="menu-title">
        OBiServer
      </button>
    </div>
    <div class="left" *ngIf="account">
      <button
        mat-button
        routerLink="/{{Const.URI_ACCOUNT}}"
        routerLinkActive="active"
        *ngIf="accountHasRole(RoleType.Admin)">
        <mat-icon>group</mat-icon>
        <div i18n>Accounts</div>
      </button>
      <button
        mat-button
        routerLink="/{{Const.URI_CLIENT}}"
        routerLinkActive="active"
        *ngIf="accountHasRole(RoleType.Admin)">
        <mat-icon>groups</mat-icon>
        <div i18n>Clients</div>
      </button>
    </div>
    <div class="right">
      <button
        mat-button
        routerLink="/{{Const.URI_ABOUT}}"
        routerLinkActive="active">
        <mat-icon>email</mat-icon>
        <div i18n>About</div>
      </button>
    </div>
    <div class="right" *ngIf="!account">
      <button
        mat-button
        routerLink="/{{Const.URI_AUTH}}/{{Const.URI_AUTH_LOGIN}}"
        routerLinkActive="active">
        <mat-icon>login</mat-icon>
        <div i18n>Login</div>
      </button>
    </div>
    <div class="right" *ngIf="account">
      <client-picker></client-picker>
      <button
        mat-button
        matTooltip="Personal settings"
        routerLink="/{{Const.URI_AUTH}}/{{Const.URI_AUTH_PERSONAL}}"
        routerLinkActive="active"
        *ngIf="!authIsNeed2fa()">
        <mat-icon>settings</mat-icon>
        <div i18n>Personal</div>
      </button>
      <button
        mat-button
        routerLink="/{{Const.URI_AUTH}}/{{Const.URI_AUTH_LOGOUT}}"
        routerLinkActive="active">
        <mat-icon>logout</mat-icon>
        <div i18n>Logout</div>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
