import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, Const} from '@app/_helper';
import {HomeComponent} from "@app/component/home/home.component";
import {AboutComponent} from "@app/component/about/about.component";

const authModule = () => import('@app/module/auth/auth.module').then(mod => mod.AuthModule);
const accountModule = () => import('@app/module/account/account.module').then(mod => mod.AccountModule);
const clientModule = () => import('@app/module/client/client.module').then(mod => mod.ClientModule);

const routes: Routes = [
  {path: Const.URI_ABOUT, component: AboutComponent},
  {path: Const.URI_HOME, component: HomeComponent, canActivate: [AuthGuard]},
  {path: Const.URI_AUTH, loadChildren: authModule},
  {path: Const.URI_ACCOUNT, loadChildren: accountModule},
  {path: Const.URI_CLIENT, loadChildren: clientModule},
  {path: '**', redirectTo: Const.URI_HOME},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
