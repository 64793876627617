{
  "name": "obiserver-hosting-frontend",
  "version": "0.0.1-SNAPSHOT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-local-dev": "ng serve --configuration local-dev",
    "start-local-prod": "ng serve --configuration local-prod",
    "start-dev": "ng serve --configuration dev",
    "start-prod": "ng serve --configuration prod",
    "build": "ng build",
    "build-local-dev": "ng build --configuration local-dev",
    "build-local-prod": "ng build --configuration local-prod",
    "build-dev": "ng build --configuration dev",
    "build-prod": "ng build --configuration prod",
    "watch": "ng build --watch --configuration dev",
    "extract-i18n": "ng extract-i18n",
    "test": "ng test --configuration prod"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.4",
    "@angular/cdk": "^19.2.7",
    "@angular/common": "^19.2.4",
    "@angular/compiler": "^19.2.4",
    "@angular/core": "^19.2.4",
    "@angular/forms": "^19.2.4",
    "@angular/localize": "^19.2.4",
    "@angular/material": "^19.2.7",
    "@angular/platform-browser": "^19.2.4",
    "@angular/platform-browser-dynamic": "^19.2.4",
    "@angular/router": "^19.2.4",
    "ng-recaptcha": "^13.2.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.5",
    "@angular/cli": "~19.2.5",
    "@angular/compiler-cli": "^19.2.4",
    "@types/jasmine": "~5.1.5",
    "eslint": "^9.16.0",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.6.3"
  }
}
