import {Component, NgModule, OnInit} from "@angular/core";
import {AccountClient} from "@app/_model";
import {AuthService} from "@app/_service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {CommonModule} from "@angular/common";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'client-picker',
  templateUrl: './client.picker.html',
  standalone: false
})
export class ClientPicker implements OnInit {
  enabled: boolean = false;
  accountClient?: AccountClient;
  accountClients?: AccountClient[];

  textNoClient = $localize `No client`;

  constructor(
    private authService: AuthService
  ) {
  }

  public ngOnInit(): void {
    this.authService.account.subscribe(account => {
      //console.debug("account:", account);
      //console.debug("account?.accountClients:", account?.accountClients);
      this.accountClients = account?.accountClients;
      this.enabled = !(this.authService.isNeed2fa);
      //console.debug("enabled:", this.enabled);
    });
    this.authService.accountClientSubject?.subscribe(accountClient => {
      //console.debug("accountClient:", accountClient);
      this.accountClient = accountClient || undefined;
    });
  }

  protected onClientChanged(accountClient: AccountClient): void {
    //console.debug("onClientChanged accountClient:", accountClient);
    this.authService.setAccountClient(accountClient);
  }
}

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    CommonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  exports: [
    ClientPicker,
  ],
  declarations: [
    ClientPicker,
  ],
})
export class ClientPickerModule {
}
