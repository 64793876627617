import {Component} from '@angular/core';
import packageJson from '../../../../package.json';
import {environment} from "@environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: false
})
export class FooterComponent {

  protected version() {
    let version = packageJson.version;
    if (environment.name != 'prod') {
      version += '@' + environment.name;
    }
    return version;
  }
}
