import {Component, OnInit} from '@angular/core';
import {Const} from "@app/_helper";
import {Account, RoleType} from "@app/_model";
import {AuthService} from "@app/_service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  standalone: false
})
export class MenuComponent implements OnInit {
  account?: Account | null;

  protected readonly Const = Const;
  protected readonly RoleType = RoleType;

  constructor(
    private authService: AuthService
  ) {
  }

  public ngOnInit(): void {
    this.authService.account.subscribe(account => this.account = account);
  }

  protected authIsNeed2fa(): boolean {
    return this.authService.isNeed2fa;
  }

  protected accountHasRole(role: RoleType): boolean {
    return this.authService.accountHasRole(role);
  }
}
