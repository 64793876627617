import {Component} from '@angular/core';
import {Account} from "@app/_model";
import {AuthService} from '@app/_service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: false
})
export class HomeComponent {

  constructor(
    private authService: AuthService
  ) {
  }

  protected get account(): Account | null {
    return this.authService.accountValue;
  }
}
