import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Alert, AlertType} from '@app/_model';
import {AlertService} from '@app/_service';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.css'],
  standalone: false
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;

  public static readonly AUTOCLOSE_TIMEOUT = 5000;

  alerts: Alert[] = [];
  alertSubscription!: Subscription;
  routeSubscription!: Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService
  ) {
  }

  public ngOnInit(): void {
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        if (!alert.message) {
          this.alerts = this.alerts.filter(alert => alert.keepAfterRouteChange);
          this.alerts.forEach(alert => delete alert.keepAfterRouteChange);
          return;
        }
        this.alerts.push(alert);
        if (alert.autoClose && (alert.autoClose === true || alert.autoClose > 0)) {
          setTimeout(() => this.removeAlert(alert), alert.autoClose === true ? AlertComponent.AUTOCLOSE_TIMEOUT : alert.autoClose);
        }
      });
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  public ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  protected removeAlert(alert: Alert, fadeTimeout?: number): void {
    if (!this.alerts.includes(alert)) return;
    if (this.fade && (fadeTimeout === undefined || fadeTimeout > 0)) {
      alert.fade = true;
      setTimeout(() => this.removeAlertReal(alert), fadeTimeout || 250);
    } else {
      this.removeAlertReal(alert);
    }
  }

  private removeAlertReal(alert: Alert): void {
    this.alerts = this.alerts.filter(alert => alert !== alert);
  }

  protected cssClass(alert: Alert): string | undefined {
    if (!alert) return;
    const classes = ['alert', 'alert-dismissible', 'mt-4', 'container'];
    if (alert.type !== undefined) {
      classes.push(AlertComponent.alertTypeClass(alert.type));
    }
    if (alert.fade) {
      classes.push('fade');
    }
    return classes.join(' ');
  }

  protected static alertTypeClass(alertType: AlertType): string {
    switch (alertType) {
      case AlertType.Success:
        return 'alert-success';
      case AlertType.Info:
        return 'alert-info';
      case AlertType.Warning:
        return 'alert-warning';
      case AlertType.Error:
        return 'alert-error';
      case AlertType.Debug:
        return 'alert-debug';
    }
  }
}
